// Main.js

import React, { useContext, useEffect, useState } from 'react'
import { Avatar, Layout, Space } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import {
    // StyledAvatarUser, 
    StyledContent, 
    StyledFooter,
    StyledHeader,
    StyledHeaderEmail,
    StyledHeaderLogo,
    StyledHeaderTitle,
} from '../common/Builders'
import { Login } from './Login'
import { APP_NAME, TESTVAR1, TESTVAR2 } from '../common/constants'
import { Dashboard } from './Dashboard'
import { MyAppContext } from '../contexts/MyAppContext'
// import logo from './logo.svg'
// import './App.css'

const AvatarUser = () => {
    return (
        <Avatar
            style={{
                backgroundColor: '#87d068',
            }}
            icon={<UserOutlined />}
        />
    )
}

export const Main = props => {

    const [ screen, setScreen ] = useState(null)
    
    const {
        authenticated,
        email,
    } = useContext(MyAppContext)

    return (
        <div className="main">
            <Space
                direction="vertical"
                style={{
                    width: '100%',
                }}
                size={[0, 48]}
            >
                <Layout>
                    <StyledHeader>
                        <StyledHeaderLogo>{'{LOGO}'}</StyledHeaderLogo>
                        <StyledHeaderTitle>{APP_NAME} {process.env.REACT_APP_STAGE}</StyledHeaderTitle>
                        <StyledHeaderEmail>
                            {
                                email && authenticated
                                    ? <>Logged in as: &nbsp;<code>{email}</code>&nbsp;<AvatarUser/></>
                                    : <>Not logged in</>
                            }
                        </StyledHeaderEmail>
                    </StyledHeader>
                    <StyledContent>

                        {/* <pre>authenticated: {authenticated ? 'yes' : 'no'}</pre> */}
                        {/* <pre>{JSON.stringify({email, authenticated}, null, 2)}</pre> */}
                        {/* <pre>screen : {screen ? screen : 'null'}</pre> */}
                        <pre>TESTVAR1 : {TESTVAR1}</pre>
                        <pre>TESTVAR2 : {TESTVAR2}</pre>

                        {
                            authenticated
                                ? <Dashboard screen={screen} setScreen={setScreen} />
                                : <Login />
                        }

                    </StyledContent>
                    <StyledFooter>Footer</StyledFooter>
                </Layout>
            </Space>
        </div>
    )
}
