// Buckets.js

import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, Carousel, Image, List, message, Modal, Radio } from 'antd'
import { CloudUploadOutlined, ContainerOutlined, FileImageOutlined, PlusOutlined } from '@ant-design/icons'
import { MyAppContext } from '../contexts/MyAppContext'
import { API_BASEURL, API_URL, axiosOptions, IMG_FALLBACK } from '../common/constants'
import { getBase64 } from '../common/functions'
import axios from 'axios'

const generateSharelink = async ({bucket, filename}) => {
    const url = `${API_URL.GENERATE_SHARELINK}/${bucket}/${filename}`
    try {
        const { data } = await axios.get(url, axiosOptions)
        console.log(14, data)
        return data
    } catch (err) { console.error(16, {err}) }
    return null
}

export const Buckets = props => {

    const { buckets, activeBucket, setActiveBucket } = props
    console.log(8, {buckets, activeBucket, setActiveBucket})

    const [previewOpen,     setPreviewOpen]     = useState(false)
    const [previewImage,    setPreviewImage]    = useState('')
    const [previewTitle,    setPreviewTitle]    = useState('')
    const [previewFname,    setPreviewFname]    = useState('')
    const [shareLinks,      setShareLinks]      = useState({}) /* { [filename]: [sharelink] } */
    // const [popupMsg,        setPopuMsg]         = useState(null)

    useEffect(() => {
        const activeBucketObject = buckets.find(e => activeBucket?.identifier === e?.identifier)
        console.log(38, {activeBucketObject})
        if (activeBucketObject?.files && Array.isArray(activeBucketObject.files)) {
            setShareLinks( Object.fromEntries(activeBucketObject.files.map(({filename, sharelink}) => ([filename, sharelink]))) )
        }
    }, [activeBucket])

    const [messageApi, contextHolder] = message.useMessage()

    const {
        dims,
    } = useContext(MyAppContext)

    // useEffect(() => {
    //     if (popupMsg) {
    //         messageApi.info(popupMsg)
    //     }
    //     setPopuMsg(null)
    // }, [popupMsg])

    const onChangeBucketSelect = e => {
        console.log(14, e.target.value)
        // setActiveBucket(e.target.value)
        const found = buckets.find(bucket => e.target.value === bucket?.identifier)
        console.log(17, found)
        if (found) setActiveBucket(found)
    }

    const clickHandleSetActiveBucket = identifier => {
        const found = buckets.find(bucket => identifier === bucket?.identifier)
        if (found) setActiveBucket(found)
    }

    const handleCancel = () => setPreviewOpen(false)
    const handlePreview = async item => {
        const imgUrl = item?.imgUrl
        if (!imgUrl) return null
        setPreviewImage(imgUrl)
        setPreviewFname(item?.filename)
        setPreviewOpen(true)
        setPreviewTitle(imgUrl.substring(imgUrl.lastIndexOf('/') + 1))
    }

    const clickHandleCreateShareLinkForPreviewFile = async filename => {
        // messageApi.info(`TODO: generate a share link in the backend for file ${previewFname}`)
        // setPopuMsg(`TODO: generate a share link in the backend for file ${previewFname}`)
        try {
            const { sharelink, filename } = await generateSharelink({bucket: activeBucket?.identifier, filename: previewFname})
            setShareLinks(prev => ({...prev, [filename]: sharelink}))
        } 
        catch (err) {
            console.log(76, {err})
        }
        // alert(`TODO: generate a share link in the backend for file ${previewFname}`)
    }

    const cardStyle = {
        style: {
            fontSize: '1.67rem',
            color: 'rgb(74 55 231)',
            cursor: 'pointer',
            margin: '1rem',
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
        }
    }
    const cardStyleActive = {
        style: {
            // backgroundColor: 'rgba(220, 95, 20, 1)',
            backgroundColor: 'rgb(238, 170, 130)',
        }
    }
    const iconStyle = {
        style: {
            fontSize: '1.2rem',
            color: 'rgb(74 55 231)',
            cursor: 'pointer',
            margin: '1rem',
            flex: 1,
        }
    }
    const detailStyle = {
        style: {
            fontSize: '1rem',
            color: 'white',
            cursor: 'pointer',
            margin: '1rem',
            flex: 2,
        }
    }

    return (
        <>
            {/* <h1>Your Buckets:</h1> */}
            <div style={{
                maxWidth: '70vw',
                border: '1px solid rgba(192, 192, 192, .85)',
                margin: '.5rem auto',
                display: 'flex',
                flexDirection: 'column',
            }}>
            {
                buckets && Array.isArray(buckets)
                ? (
                    buckets.map((e, i) => {
                        const isActive = activeBucket?.identifier === e?.identifier
                        const thisCardStyle = isActive ? { style: {...cardStyle.style, ...cardStyleActive.style}} : {...cardStyle}
                        const activeBucketObject = buckets.find(e => activeBucket?.identifier === e?.identifier)
                        console.log(113, activeBucketObject)
                        const thumbArray = activeBucketObject && activeBucketObject?.files
                            ? activeBucketObject.files.map((e, i) => {
                                const {  
                                    originalname,
                                    mimetype,
                                    filename,
                                    size,
                                    thumb,
                                } = e
                                return (
                                    {
                                        filename,
                                        originalname,
                                        mimetype,
                                        size,
                                        thumbUrl: `${API_BASEURL}/th/${thumb}`,
                                        imgUrl: `${API_BASEURL}/img/${filename}`,
                                    }
                                )
                            })
                            : []
                        console.log(128, thumbArray)
                        return (<div
                            key={`showbucket-${i+1}`}
                            style={{ border: '0px solid salmon', display: 'flex', }}
                            onClick={evt => clickHandleSetActiveBucket(e?.identifier)}
                        >
                            <Card bordered={true} {...thisCardStyle}>
                                <ContainerOutlined {...iconStyle} />
                                &nbsp;&nbsp;
                                {e?.identifier}
                            </Card>

                            <div {...detailStyle}>
                                {isActive
                                    ? (<List
                                            dataSource={thumbArray}
                                            // itemLayout="vertical"
                                            // footer={
                                            //     <div>
                                            //         <i><b>{e?.identifier}</b></i>
                                            //     </div>
                                            // }
                                            pagination={{
                                                onChange: page => {
                                                    console.log(page)
                                                },
                                                // pageSize: 3,
                                            }}
                                            bordered={true}
                                            // grid={{gutter: 4, column: 3}}
                                            renderItem={(item, index) => {

                                                // const shared = item?.filename && shareLinks[item.filename]
                                                //     ? ` - ${shareLinks[item.filename]}`
                                                //     : ''
                                                const shared = item?.filename && shareLinks[item.filename]
                                                    ? (<a href={`${API_BASEURL}/sh/${shareLinks[item.filename]}`}>SHARELINK: {shareLinks[item.filename]}</a>)
                                                    : null

                                                return (<List.Item
                                                    key={`th-${e?.identifier}-${index+1}`}
                                                    onClick={e => handlePreview(item)}
                                                    extra={
                                                        (
                                                            <>
                                                                <>
                                                                    {item?.size ? `${Math.round(item.size / 1024)}` : null} KB
                                                                    &nbsp;&nbsp;&nbsp;
                                                                </>
                                                                {/* <img src={item?.imgUrl} height={90} /> */}
                                                                <img src={item?.thumbUrl} height={90} />
                                                            </>
                                                        )
                                                        // <Image
                                                        //     width={90}
                                                        //     height={90}
                                                        //     src={item?.imgUrl}
                                                        //     fallback={IMG_FALLBACK}
                                                        // />
                                                    }
                                                >
                                                    <List.Item.Meta
                                                        title={item?.originalname}
                                                        description={`${item?.mimetype}`}
                                                    />
                                                    {/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
                                                    {/* <span>{item?.originalname}</span> */}
                                                    <pre style={{background: 'white'}}>{shared}</pre>
                                                </List.Item>)
                                            }}
                                        />)
                                    : null
                                }
                            </div>
                        </div>)
                    })
                )
                : null
            }
            </div>

            {/* <Button type="default" shape="round" icon={<PlusOutlined />} size={24}>
                New bucket
            </Button> */}
            <Button danger shape="round" icon={<PlusOutlined />} size={'large'}>
                New bucket
            </Button>

            <Modal
                open={previewOpen}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
                // width={dims?.width ? Math.round(dims.width/2) : 456}
                width={dims?.width ? Math.round(dims.width * .55) : 456}
            >
                <>
                    <img
                        alt="preview"
                        style={{
                            width: '100%',
                        }}
                        src={previewImage}
                    />
                    <Button
                        onClick={clickHandleCreateShareLinkForPreviewFile}
                    >Share</Button>
                    {/* <div>{shareLinks.find(e => e?.filename === previewFname)}</div> */}
                    <div>{Object.keys(shareLinks).find(key => key === previewFname)}</div>
                    <pre>{JSON.stringify(shareLinks, null, 2)}</pre>
                </>
            </Modal>

        </>
    )
}
