// MyAppContext.js

import { createContext } from 'react'

export const MyAppContext = createContext({
    authenticated: false,
    setAuthenticated: () => {},
    email: null,
    setEmail: () => {},
    emailStatus: null,
    setEmailStatus: () => {},
})
