// Dashboard.js

import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { Card, Col, Divider, Row, Space } from 'antd'
import { CloudUploadOutlined, FileImageOutlined } from '@ant-design/icons'
import { MyAppContext } from '../contexts/MyAppContext'
import { FileUpload } from './FileUpload'
import { Buckets } from './Buckets'
import { API_URL, axiosOptions } from '../common/constants'

axios.defaults.withCredentials = true /* GOTCHA: if missing cookies are not retained */

const getUserBuckets = async formData => {
    // const url = 'http://localhost:8007/get-buckets'
    const url = API_URL.GET_BUCKETS
    try {
        const { data } = await axios.get(url, axiosOptions)
        console.log(33, data)
        return data
    } catch (err) { console.error(35, {err}) }
    return null
}

export const Dashboard = props => {

    const {
        screen,
        setScreen,
    } = props

    const {
        authenticated,
        email,
    } = useContext(MyAppContext)

    const [ buckets,        setBuckets ]        = useState([])
    const [ activeBucket,   setActiveBucket ]   = useState(null)
    const [ refreshBuckets, setRefreshBuckets ] = useState(false)

    const fetchBuckets = async () => {
        let { buckets } = await getUserBuckets()
        console.log(60, buckets)
        if (buckets && Array.isArray(buckets)) setBuckets([...buckets])
        if (buckets && Array.isArray(buckets) && buckets.length === 1) {
            let [_activeBucket] = buckets
            setActiveBucket(buckets[0])
        }
    }

    useEffect(() => {
        fetchBuckets()
    }, [])

    useEffect(() => {
        fetchBuckets()
        setRefreshBuckets(false)
    }, [refreshBuckets])

    if (!authenticated) return null

    const iconStyle = { style: { fontSize: '1.67rem', color: 'rgb(74 55 231)', cursor: 'pointer', } }

    const handleClickFiles = e => {
        setRefreshBuckets(true)
        setScreen('buckets')
    }

    const handleClickUpload = e => setScreen('upload')

    console.log(77, {buckets, activeBucket_identifier: activeBucket?.identifier})

    return (
        <div style={{ margin: '3rem 0' }}>
            <Divider plain />
            <p>
                Upload new image files or list your file buckets
            </p>
            <Divider plain />
            <Row gutter={32}>
                <Col span={4} offset={8}>
                    <Card bordered={true} {...iconStyle} onClick={handleClickUpload}>
                        <CloudUploadOutlined {...iconStyle} />
                        &nbsp;&nbsp;
                        Upload
                    </Card>
                </Col>
                <Col span={4} offset={0}>
                    <Card bordered={true} {...iconStyle} onClick={handleClickFiles}>
                        <FileImageOutlined {...iconStyle} />
                        &nbsp;&nbsp;
                        Buckets
                    </Card>
                </Col>
            </Row>
            <Divider plain />

            {/* <pre>Active bucket:{
                activeBucket && activeBucket?.identifier
                    ? activeBucket.identifier
                    : null
            }</pre> */}

            {
                screen === 'upload'
                    ? (
                        <>
                            {/* <input type="file" onChange={handleFileUpload} id={'files'} name={'upload_files'} multiple />  */}
                            {/* <pre>buckets: { JSON.stringify(buckets, null, 2) }</pre> */}
                            {/* <pre>Buckets:{ 
                                Array.isArray(buckets)
                                    ? buckets.map((e, i) => (
                                        <div key={`debugbucket-${i+1}`}>
                                            {i+1}. &nbsp;
                                            {e?.identifier} - {e?.email_addr}
                                        </div>
                                    ))
                                    : null
                            }</pre> */}

                            {
                                activeBucket
                                    ? (
                                        <FileUpload
                                            // name={'upload_files'}
                                            bucketname={activeBucket?.identifier}
                                            filesInActiveBucket={buckets.find(bucket => bucket.identifier === activeBucket?.identifier)?.files}
                                            {...{setRefreshBuckets, email}}
                                        />
                                    )
                                    : (<>
                                        <p>Click to Set an active bucket:</p>
                                    </>)
                            }
                            {/* <Buckets {...{buckets, activeBucket, setActiveBucket}}/> */}
                        </>
                    )
                    : null
            }
            {
                screen === 'buckets'
                    ? (<Buckets {...{buckets, activeBucket, setActiveBucket}}/>)
                    : null
            }
        </div>
    )
}
