// FileUpload.js

/* cf. https://stackoverflow.com/questions/58128062/using-customrequest-in-ant-design-file-upload */
/* cf. https://ant.design/components/upload#examples */

import React, { useEffect, useState } from 'react'
import { InboxOutlined, PlusOutlined } from '@ant-design/icons'
import { message, Modal, Upload } from 'antd'
import axios from 'axios'
import { API_BASEURL, API_URL } from '../common/constants'
import '../assets/css/fileupload.css'
import { getBase64 } from '../common/functions'

const { Dragger } = Upload

const axiosOptions = {
    credentials: 'same-origin', /* GOTCHA: if missing cookies are not sent/set */
    headers: {
        'Content-Type': 'multipart/form-data',
    }
}
axios.defaults.withCredentials = true /* GOTCHA: if missing cookies are not retained */

export const FileUpload = props => {

    const {
        email,
        bucketname,
        setRefreshBuckets,
        filesInActiveBucket,
    } = props

    // const [fileList, setFileList] = useState(Array.isArray(filesInActiveBucket) || [])
    const [fileList,        setFileList]        = useState([])
    const [previewOpen,     setPreviewOpen]     = useState(false)
    const [previewImage,    setPreviewImage]    = useState('')
    const [previewTitle,    setPreviewTitle]    = useState('')

    console.log(37, {bucketname, filesInActiveBucket})

    useEffect(() => {
        // setFileList()
            // uid: '-2',
            // name: 'image.png',
            // status: 'done',
            // url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        setFileList(
            filesInActiveBucket.map((e, i) => {
                const { filename, thumb } = e
                // const url = `${API_BASEURL}/th/${thumb}`
                const url = `${API_BASEURL}/img/${filename}`
                return {
                    uid: -1 * (i+1),
                    name: filename,
                    status: 'done',
                    url,
                }
            })
        )
    }, [filesInActiveBucket])

    const handleChange = ({ file, fileList: newFileList, event })  => {
        // console.log(13, {file, fileList})
        console.log(33, {file, fileList, event})
        setFileList(newFileList)
    }

    const handleCancel = () => setPreviewOpen(false)
    const handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj)
        }
        setPreviewImage(file.url || file.preview)
        setPreviewOpen(true)
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
    }

    /* Note this handles a single file upload, if multiple are selected, the upload happens "in parallel" */
    const doFileUpload = async options => {
        console.log(41, options ? Object.keys(options) : null)
        // if (options && Object.keys(options)) Object.keys(options).map(key => console.log(21, key, typeof options[key] !== 'function' ? options[key] : '-'))

        const { onSuccess, onError, file, onProgress } = options
        // console.log(40, options?.data)

        /* get the bucketname */
        const { bucketname } = options?.data || {}

        const formData = new FormData()
        formData.append('upload_files', file)
        formData.append('bucketname', bucketname)

        // const url = 'http://localhost:8007/upload-files'
        const url = API_URL.UPLOAD_FILES
        try {
            const { data } = await axios.post(url, formData, axiosOptions)
            console.log(47, data)

            /* make sure the uploaded file appears in the bucket */
            setRefreshBuckets(true)

            return data
        } catch (err) { console.error(49, {err}) }
        return null
    }

    return (
        <>
            <Dragger
                // {...props}
                data={{ bucketname }}
                onChange={handleChange}
                onPreview={handlePreview}
                customRequest={doFileUpload}
                multiple={true}
                listType="picture-card"
                fileList={fileList}
                // itemRender={(remove) => null} /* TODO:  */
                onRemove={() => false} /* prevent item being deleted, NOTE: also need to hide the trash icon using css */
                style={{
                    background: 'rgba(192, 192, 192, 1)',
                    // border: '5px solid crimson',
                    paddingBottom: '1rem',
                    marginBottom: '2rem',
                }}
            >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file(s) to this area to start uploading into <b><em>{bucketname}</em></b> bucket.</p>
                <p className="ant-upload-hint">
                    Support for a single or bulk upload.
                </p>
                
            </Dragger>
            <p>{bucketname ? `Bucket: ${bucketname}` : null}</p>
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img
                    alt="preview"
                    style={{
                        width: '100%',
                    }}
                    src={previewImage}
                />
            </Modal>
        </>
    )
}
