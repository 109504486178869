// App.js

// import logo from './logo.svg'
// import './App.css'
import { useEffect, useState } from 'react'
import { Main } from './components/Main'
import { GlobalStyle } from './common/Builders'
import { MyAppContext } from './contexts/MyAppContext'

const existingEmail = 'mgreyling+picshare1@gmail.com'
const reEmail = /^\w+([\.-\\+]?\w+)*@\w+([\.-\\+]?\w+)*(\.\w{2,3})+$/ /* allow plus sign (+) */

export const App = () => {

    const [ authenticated, setAuthenticated ] = useState(false)
    const [ email, setEmail ] = useState(null)
    const [ emailStatus, setEmailStatus ] = useState(false)
    const [ dims,   setDims ]   = useState(null)

    useEffect(() => {
        const handleResize = () => {
            const { innerHeight, innerWidth } = window
            setDims({
                height: innerHeight,
                width: innerWidth,
            })
        }
        handleResize()
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])


    // useEffect(() => {
    //     const picshare_email = localStorage.getItem('picshare_email')
    //     setEmail(picshare_email || existingEmail)
    // }, [])

    // useEffect(() => {
    //     const valid = email ? reEmail.test(email.trim()) : false
    //     if (authenticated && valid) localStorage.setItem('picshare_email', email)
    // }, [authenticated])

    return (
        <MyAppContext.Provider
            value={{
                authenticated, setAuthenticated,
                email, setEmail,
                emailStatus, setEmailStatus,
                dims,
            }}
        >
            <GlobalStyle />
            <Main { ...{ authenticated, email }} />
        </MyAppContext.Provider>
    )
}

// export default App

/* 
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;

*/