// Builders.js

import styled, { createGlobalStyle, keyframes } from 'styled-components'
import {
    // Avatar,
    Layout,
} from 'antd'
import { LOADING_VOLUMIO } from './constants'
// import loading_volumio from '../assets/img/loading_volumio.svg'

const {
    Header,
    Content,
    Footer,
} = Layout

export const GlobalStyle = createGlobalStyle`
    html, body {
        margin: 0;
        padding: 0;
    }
    *, *::after, *::before {
        box-sizing: border-box;
    }
    label {
        margin-bottom: 1rem;
    }
    input {
        margin-bottom: 1.5rem;
        bottom: 1rem;
    }
`

export const StyledHeader = styled(Header)`
    text-align: center;
    color: rgb(255, 255, 255);
    height: 64px;
    padding-inline: 50px;
    line-height: 64px;
    // background-color: rgb(125, 188, 234); /* light blue */
    background-color: rgb(74 55 231); /* purple */
    position: sticky;
    top: 0;
    zIndex: 1,
    width: 100%;
    align-items: center;
    // border: 5px solid salmon;
    border: 3px solid rgba(125, 188, 234, .67);
    display: flex;
    flex-direction: row;
    z-index: 2;
`

export const StyledHeaderItem = styled.div`
    flex: 1;
`

export const StyledHeaderLogo = styled(StyledHeaderItem)`

`
export const StyledHeaderTitle = styled(StyledHeaderItem)`
    font-size: 1.5rem;
    font-weight: 900;
`
export const StyledHeaderEmail = styled(StyledHeaderItem)`
    font-weight: 400;
`

export const StyledContent = styled(Content)`
    text-align: center;
    min-height: 120px;
    // line-height: 120px;
    line-height: 2rem;
    color: rgb(255, 255, 255);
    background-color: rgb(16, 142, 233);
    flex: 1;
    padding: 1rem;
    min-height: calc(100vh - 2rem);
    min-height: 100vh;
    padding-top: 1rem;
    padding-bottom: 1.1rem;
`

export const StyledFooter = styled(Footer)`
    text-align: center;
    color: rgb(255, 255, 255);
    // background-color: rgba(125, 188, 234, 1);
    // background-color: rgba(74, 55, 231, .82); /* purple */
    background-color: rgba(74, 55, 231, 1); /* purple */
    width: 100%;
    // align-items: center;
    border: 3px solid rgba(125, 188, 234, .67);

    position: sticky;
    bottom: 0;
`

/* used to detect a spam bot */
export const LabelHiddenFromHumanView = styled.label`
    visibility: hidden;
    display: block;
    height: 0;
    width: 0;
    > p {
        height: 0;
    }
    > input {
        height: 0;
    }
`

export const LoginWrapper = styled.div`
    // position: relative;
`

const fadeOut = keyframes`
    0%      {   opacity: 1; }
    100%    {   opacity: 0; }
`

const fadeIn = keyframes`
    0%      {   opacity: 0; }
    100%    {   opacity: 1; }
`

export const LoadingDiv = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    // height: 100%;
    // width: 100%;
    border: ${props => props?.$isActive ? '5px solid crimson' : '1px solid green'};
    // height: ${props => props?.$isActive ? 'auto' : '0'};
    // opacity: ${props => props?.$isActive ? '1' : '0'};
    display: ${props => props?.$isActive ? 'block' : 'none'};
    // background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><linearGradient id='gradient'><stop offset='10%' stop-color='%23F00'/><stop offset='90%' stop-color='%23fcc'/> </linearGradient><rect fill='url(%23gradient)' x='0' y='0' width='100%' height='100%'/></svg>");
    background-image: url(${LOADING_VOLUMIO});
    background-color: rgba(255, 255, 255, .85);
    // animation: ${fadeOut} ease-out 6s;
    animation: ${props => props?.$isActive ? `fadeOut ease-out 6s` : ''};
    animation-delay: 2s;
    // animation-fill-mode: forwards; /* prevent re-appearing */
    // transition: opacity 5s ease-in-out;
`

export const LoginInstruction = styled.span`
    // background: white;
    background: rgba(255, 255, 255, .33);
    font-size: 1.5rem;
    font-weight: 900;
    // color: rgba(66, 66, 66, .67);
    color: rgba(255, 255, 255, 1);
    flex: 1;
`

// export const StyledAvatarUser = styled(Avatar)`
    // backgroundColor: #fde3cf;
    // color: #f56a00';
// `
