// Login.js

import React, { useContext, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { LabelHiddenFromHumanView, LoadingDiv, LoadingDivActive, LoginInstruction, LoginWrapper } from '../common/Builders'
import { MyAppContext } from '../contexts/MyAppContext'
import { API_URL, EMAIL_ADDR_STATUS } from '../common/constants'

const axiosOptions = {
    // mode: 'same-origin',
    // redirect: 'follow',
    credentials: 'same-origin', /* GOTCHA: if missing cookies are not sent/set */
    headers: {
        'Content-Type': 'application/json'
    }
}
axios.defaults.withCredentials = true /* GOTCHA: if missing cookies are not retained */

const loginUser = async credentials => {
    // const url = 'http://localhost:8007/login'
    const url = API_URL.LOGIN
    try {
        const { data } = await axios.post(url, credentials, axiosOptions)
        console.log(34, data)
        return data
    } catch (err) { console.error(34, err) }
    return null
}

const triggerEmailPIN = async credentials => {
    // const url = 'http://localhost:8007/sendpin'
    const url = API_URL.SEND_PIN
    try {
        const { data } = await axios.post(url, credentials, axiosOptions)
        console.log(34, data)
        return data
    } catch (err) { console.error(34, err) }
    return null
}

const verifyPIN = async credentials => {
    // const url = 'http://localhost:8007/verifypin'
    const url = API_URL.VERIFY_PIN
    try {
        const { data } = await axios.post(url, credentials, axiosOptions)
        console.log(56, data)
        return data
    } catch (err) { console.error(58, err) }
    return null
}


const getAuthStatus = async () => {
    // const url = 'http://localhost:8007/auth-status'
    const url = API_URL.AUTH_STATUS
    try {
        // const { data } = await axios.post(url, {}, axiosOptions)
        const { data } = await axios.get(url, axiosOptions)
        console.log(71, data)
        return data
    } catch (err) { console.error(73, {err}) }
    return null
}

export const Login = () => {

    const {
        authenticated, setAuthenticated,
        email, setEmail,
        emailStatus, setEmailStatus,
    } = useContext(MyAppContext)

    const [ loading, setLoading ] = useState(true)
    // const [ checked, setChecked ] = useState(false)
    const [ honeyPot, setHoneyPot ] = useState(false)
    const [ pin, setPin ] = useState('')

    useEffect(() => {
        ;(async () => {
            /* check if secure cookies with email and token match values stored in remote db */
            const data = await getAuthStatus()
            console.log(91, {data})
            if (    data?.authenticated
                &&  data?.email_address_status === EMAIL_ADDR_STATUS.VERIFIED
                &&  data?.email
            ) {
                setAuthenticated(true)
                setEmailStatus(EMAIL_ADDR_STATUS.VERIFIED)
                setEmail(data.email)
            }
        })()
    }, [])

    useEffect(() => {
        // // setEmail(existingEmail)
        // ;(async () => {
        //     if (email) {
        //         const data = loginUser({
        //             email: emailRef.current.value,
        //         })
        //         console.log(97, data)
        //     }
        //     // else setEmail(existingEmail)
        // })()

        setTimeout(() => setLoading(false), 2000)
    }, [])

    useEffect(() => {
        ;(async () => {
            if (typeof pin === 'string' && pin.trim().length === 6) {
                // alert(pin)
                setLoading(true)
                const data = await verifyPIN({
                    email: emailRef.current.value,
                    // pin: pinRef?.current?.value,
                    pin,
                })
                console.log(101, data)
                /* authenticated: true, email_address_status: 0 */
                const { authenticated, email_address_status } = data
                if (authenticated && email_address_status === EMAIL_ADDR_STATUS.VERIFIED) {
                    setAuthenticated(true)
                }
                setTimeout(() => setLoading(false), 3500)
            }
        })()
    }, [pin])

    const emailRef = useRef()
    const pinRef = useRef()

    // const handleSubmit = async e => {
    const handleCheckEmailAddr = async e => {
        e.preventDefault()
        setLoading(true)
        console.log(24, 'emailRef.current.value', emailRef.current.value)
        const data = await loginUser({
            email: emailRef.current.value,
            // password: passwordRef.current.value
        })
        console.log(131, data)
        const { email_address_status } = data
        if (email_address_status) setEmailStatus(email_address_status)
        //window.location.reload(false)

        // const result = await getAuthStatus()
        // console.log(42, result)
        setLoading(false)
    }

    const handleVerifyEmailWithPIN = async e => {
        e.preventDefault()
        setLoading(true)
        // alert('PIN!!!')
        const data = await triggerEmailPIN({
            email: emailRef?.current?.value,
            // password: passwordRef.current.value
        })
        console.log(111, data)
        const { email_address_status } = data
        if (email_address_status) setEmailStatus(email_address_status)
        setLoading(false)
    }
    
    // const handleInputOfPIN = async e => {
    //     e.preventDefault()
    //     setLoading(true)
    //     const data = await verifyPIN({
    //         email: emailRef.current.value,
    //         // pin: pinRef?.current?.value,
    //         pin,
    //     })
    //     console.log(143, data)
    //     setLoading(false)
    // }

    const emailAddressStatus = emailStatus && EMAIL_ADDR_STATUS
        ? Object.keys(EMAIL_ADDR_STATUS).find(key => EMAIL_ADDR_STATUS[key] === emailStatus)
        : null

    let handleSubmit,
        stage,
        instruction,
        btn = <button type="submit">Submit</button>,
        pinInput
    if (emailStatus === EMAIL_ADDR_STATUS.UNKNOWN || !emailStatus) {
        handleSubmit = handleCheckEmailAddr
        stage = 'First'
    }
    if (emailStatus === EMAIL_ADDR_STATUS.UNVERIFIED) {
        handleSubmit = handleVerifyEmailWithPIN
        stage = '2nd'
        instruction = <p><LoginInstruction>Send confirmation code to email address?</LoginInstruction></p>
        btn = <button type="submit">Send CODE</button>
    }
    if (emailStatus === EMAIL_ADDR_STATUS.PIN_SENT) {
        // handleSubmit = handleInputOfPIN
        stage = '3rd'
        instruction = <p><LoginInstruction>Send confirmation code to email address?</LoginInstruction></p>
        btn = <button type="submit">Send CODE</button>
        pinInput = (
            <label>
                <div>Enter code sent to email address</div>
                <input type="text" ref={pinRef} value={pin || ''} onChange={e => setPin(e?.target?.value)} />
            </label>
        )
    }
    if (emailStatus === EMAIL_ADDR_STATUS.VERIFIED) {
        /* yippee! */
        setAuthenticated(true)
    }

    /* TODO: create a bunch of honey trap hidden fields to detect non human interaction */
    const handleHoneyPotTrigger = e => {
        if (e?.target?.value) {
            setHoneyPot(true)
            emailRef.current.value = 'REDACTED'
        }
    }
    const honeyTrap = (
        <LabelHiddenFromHumanView>
            <p>This needs to be completed:</p>
            <input type="text" name="i_hate_spam" onChange={handleHoneyPotTrigger} value="" />
        </LabelHiddenFromHumanView>
    )

    return (
        <LoginWrapper className="login-wrapper">
            {/* <LoadingDiv className="loading-div"></LoadingDiv> */}
            {/* <LoadingDivActive className="loading-div-active">&nbsp;</LoadingDivActive> */}
            {/* {
                loading
                    ? <LoadingDivActive className="loading-div-active">&nbsp;</LoadingDivActive>
                    : <LoadingDiv className="loading-div"></LoadingDiv>
            }  */}
            <LoadingDiv className="loading-div" $isActive={!!loading}></LoadingDiv>
            <h1>Login</h1>
            {emailAddressStatus ? (<pre>emailAddressStatus: { emailAddressStatus }</pre>) : null}
            <pre>stage: { stage }</pre>
            <form onSubmit={handleSubmit}>
                {honeyTrap}
                <label>
                    <div>Email address</div>
                    <pre>FOR TESTING USE: mgreyling+picshare2@gmail.com</pre>
                    {/* <input type="email" ref={emailRef} defaultValue={existingEmail} _value={existingEmail} /> */}
                    <input type="email" ref={emailRef} value={email || ''} onChange={e => setEmail(e.target.value)} />
                </label>
                {pinInput}
                <div>
                    {instruction}
                    {
                        honeyPot === true
                            ? <h1>Bot detected!</h1>
                            : btn
                    }
                </div>
            </form>
            {/* <p>{checked ? 'checked' : 'unchecked'}</p>
            <input
                type="checkbox"
                checked={checked}
                // onChange={setChecked(prev => !prev)}
            /> */}
        </LoginWrapper>
    )
}
